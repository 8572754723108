import {
  UIRouter,
  BaseLocationServices,
  stripLastPathElement,
} from '@uirouter/core';
import type { ILocation } from '@wix/yoshi-flow-editor';

import type { WixLocationConfig } from './WixLocationConfig';

export class PushLocationService extends BaseLocationServices {
  constructor(protected router: UIRouter, protected location: ILocation) {
    super(router, false);

    location.onChange?.(this.handleLocationChange.bind(this));
  }

  handleLocationChange() {
    this._listener(new Event(''));
  }

  protected _get(): string {
    const config = this.router.locationConfig as WixLocationConfig;

    const pathname = config.pathname();
    const hash = config.hash();
    const search = config.search();

    const baseUrl = stripLastPathElement(config.baseHref());

    return pathname.substring(baseUrl.length) + search + hash;
  }

  protected _set(state: any, title: string, url: string, replace: boolean) {
    const normalizedUrl = url.startsWith('/') ? url : `/${url}`;
    const cleanUrl = normalizedUrl.replace(/([^:]\/)\/+/g, '$1');

    this.location.to!(normalizedUrl, { disableScrollToTop: true });
  }
}
