import { UIRouter } from '@uirouter/core';
import type { InitAppForPageFn } from '@wix/yoshi-flow-editor';

import { controllerPlugin } from './router/controller-plugin';
import { createStore } from './store';
import { actions } from './store/application';
import { getApplicationRoutes } from './routes';

import type { IAppData } from './types';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServicesApis,
  flowAPI,
) => {
  const router = new UIRouter();
  const states = await getApplicationRoutes(wixCodeApi.site);
  const store = createStore(flowAPI, wixCodeApi);

  store.dispatch(
    actions.setupRouter({
      states,
      baseUrl: wixCodeApi.location.baseUrl,
    }),
  );

  router.plugin(controllerPlugin(flowAPI));

  return {
    store,
    router,
  } as IAppData;
};
